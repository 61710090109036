import {Accordion} from './Accordion.js';
import {lottieAnimations} from './animations/animations.js';
import {Dropdown} from './Dropdown';
import {Modal} from './Modal.js';
import {Nav} from './Nav.js';
import {ScrollInteraction} from './ScrollInteraction';
import {pictoSlider, simpleSlider} from './sliders';

// Load all SVGs to combine them into a sprite
require.context('../svg', true, /\.svg$/);

const modals = document.querySelectorAll('.js-modal');
for (let modalEl of modals) {
    modalEl.modal = new Modal(modalEl);
}

const accordions = document.querySelectorAll('.js-accordion');
for (let accordionEl of accordions) {
    accordionEl.accordion = new Accordion(accordionEl);
}

new Dropdown(
    document.querySelector('.lang-dropdown'),
    document.querySelector('.lang-dropdown-current'),
    document.querySelector('.lang-dropdown-languages'),
);
new Dropdown(
    document.querySelector('.search-form-wrapper'),
    document.querySelector('.search-form-toggle'),
    document.querySelector('.search-form'),
);
new Nav();
new ScrollInteraction();

document.querySelectorAll('.splide-picto').forEach(slider => pictoSlider(slider));
document.querySelectorAll('.splide-simple').forEach(slider => simpleSlider(slider));

lottieAnimations();
