const {__} = wp.i18n;

export const i18n = {
    prev: __('Slide précédente', 'oxiane'),
    next: __('Slide Suivante', 'oxiane'),
    first: __('Aller à la première slide', 'oxiane'),
    last: __('Aller à la dernière slide', 'oxiane'),
    slideX: __('Aller à la slide %s', 'oxiane'),
    pageX: __('Aller à la page %s', 'oxiane'),
    play: __('Commencer la lecture automatique', 'oxiane'),
    pause: __('Mettre en pause la lecture automatique', 'oxiane'),
    select: __('Chisissez une slide à afficher', 'oxiane'),
    slideLabel: __('%s de %s', 'oxiane'),
};
